import $ from 'jquery'
import 'admin-lte/plugins/select2/js/select2'

$(document).on('turbolinks:load ready', function() {
  $('.simple_form').on('change', '.custom-file-input', function() {

    $(this).parent().parent().children('label.custom-file-label').text($(this)[0].files[0].name);
  });

  $(document).on("cocoon:after-insert", function() {
    Parsley.$element.find('.parsley-form').parsley().isValid();
  });

  $(".alert:not(.alert-info)").delay(5000).slideUp(200);

  if($('#category-select').length > 0) {
    $('#category-select').select2().on('change', function() {
      manageItemCategorySelect($(this));
    });
  }

  if($('.trix-input').length > 0)
    $('.trix-input').removeAttr('required');

  $('.panel-collapse').on('show.bs.collapse', function () {
    $(this).siblings('.panel-heading').addClass('active');
  });

  $('.panel-collapse').on('hide.bs.collapse', function () {
    $(this).siblings('.panel-heading').removeClass('active');
  });

  $("#request-quote-btn").click(function(event) {
    event.preventDefault();

    let form = document.querySelector('#new_quote');
    Rails.fire(form, 'submit');

    if(Parsley.$element.find('#new_quote').parsley().isValid()) {
      $(this).prop("disabled", true);

      $(this).html(
        `<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> <span class="sr-only">Loading...</span>`
      );
    }
  });

  // TODO: Move these utilities to a different folder?
  $(".url-to-clipboard").click(function(event) {
    let url = null;
    if (this.hasAttribute("data-route")) {
      url = window.location.origin + this.dataset.route;
    } else if (this.hasAttribute("data-url")) {
      url = this.dataset.url;
    } else {
      console.error(".url-to-clipboard element missing data-* attributes.");
      return;
    }
    // Create a temporary input element
    var tempInput = document.createElement("input");
    // Set its value to a full URL
    tempInput.setAttribute("value", url);
    // Append the input element to the DOM
    document.body.appendChild(tempInput);
    // Select the value of the input element
    tempInput.select();
    // Execute the copy command
    document.execCommand("copy");
    // Remove the temporary input element from the DOM
    document.body.removeChild(tempInput);
  });

  $('#customer-search-form select').select2({
    ajax: {
      url: '/admin/customers',
      dataType: 'json',
      delay: 250,
      data: function (params) {
	return {
	  filter: params.term
	};
      },
      processResults: function (data) {
	return {
	  results: $.map(data, function (customer) {
	    return {
	      id: customer.id,
	      text: customer.name
	    };
	  })
	};
      },
      cache: true
    },
    placeholder: 'Search for a customer...',
    minimumInputLength: 2
  });
})

function manageItemCategorySelect(categorySelect) {
  $.ajax({
    url: `/admin/categories/${categorySelect.val()}/fetch_sub_categories_list`,
    dataType: 'script'
  });
}
