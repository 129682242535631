import $ from 'jquery'
import 'admin-lte/plugins/select2/js/select2'

$(document).on('turbolinks:load ready', function() {
  $('.select2').select2();

  if($('#autocomplete-select').length > 0) {
    $("#autocomplete-select").select2({
      ajax: {
        url: '/items',
        dataType: 'json',
        delay: 250,
        data: function (params) {
          return {
            q: params.term
          };
        },
        processResults: function (data) {
          return {
            results: $.map(data, function (item) {
              return {
                id: item.id,
                text: item.title
              }
            })
          };
        },
        cache: true
      },
      placeholder: 'Search for an Item',
      minimumInputLength: 1,
    });
  }
});
