import Rails from "@rails/ujs";
window.Rails = Rails;
Rails.start();

require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("trix")
require("@rails/actiontext")

import 'jquery'
import 'admin-lte/dist/js/adminlte.min'
import 'admin-lte/plugins/bootstrap/js/bootstrap.bundle'
require('@fortawesome/fontawesome-free/css/all');
require('@fortawesome/fontawesome-free/js/all');
import 'custom/parsley'
import 'custom/tooltips'
import 'cocoon'
import 'custom/lightbox'
import 'custom/sweetalert'
import 'custom/select2'
import 'custom/script'
import 'custom/masonry_layout'

// Expose JQuery to window
window.jQuery = $;
window.$ = $;
