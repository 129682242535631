import $ from 'jquery'
import 'parsleyjs'
import 'admin-lte/plugins/bootstrap/js/bootstrap.bundle';

$(document).on('turbolinks:load ready', function() {
  $('[data-toggle="tooltip"]').tooltip();

  $('#copy-to-clipboard').on('click', function() {
    let copyText = document.getElementById($(this).attr('data-copy-target'));

    copyText.select();
    copyText.setSelectionRange(0, 99999);
    document.execCommand("copy");

    $(this).attr('data-original-title', 'Copied!');
    $(this).tooltip('show');
  });
});
