import $ from 'jquery'
import Masonry from 'masonry-layout'

$(window).on('load', function() {
  if($('.masonry-grid').length > 0) {
    var grid = document.querySelector('.masonry-grid');

    var msnry = new Masonry( grid, {
      itemSelector: '.masonry-grid-item'
    });

    msnry.layout()
  }
});
